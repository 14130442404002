<template>
  <div>
    <b-row class="match-height">
      <b-col lg="12">
        <b-overlay
          :show="ShowRound"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <b-icon-controller
                font-scale="3"
                animation="cylon"
              />
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <b-card>
            <validation-observer ref="simpleRound">
              <b-row>
                <b-col lg="6">
                  <h3>เลือกหวย</h3>
                </b-col>
              </b-row>

              <b-form-group
                label="หวย"
                label-for="customDelimiter"
              >
                <validation-provider
                  #default="{ errors }"
                  name=""
                  rules="required"
                >
                  <v-select
                    v-model="LottoHead"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="LottoList"
                    :state="errors.length > 0 ? false : null"
                    :reduce="(ele) => ele.value"
                    @input="
                      (LottoSubHead = null),
                      FindSublist(LottoHead),
                      CheckRound(),
                      ClearItems()
                    "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="LottoSubList"
                label="ประเภทหวย"
                label-for="customDelimiter"
              >
                <validation-provider
                  #default="{ errors }"
                  name=""
                  rules="required"
                >
                  <v-select
                    v-model="LottoSubHead"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="LottoSubList"
                    :state="errors.length > 0 ? false : null"
                    :reduce="(ele) => ele.value"
                    @input="ClearItems(), CheckRound()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div
                v-if="
                  ((LottoHead && !LottoSubList) || LottoSubHead) &&
                    Object.keys(RoundData).length === 0
                "
              >
                <div class="alert alert-primary p-1 text-center">
                  <i class="fa fa-folder-open" /> ไม่พบรอบหวย
                </div>
              </div>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>

      <b-col
        v-if="
          ((LottoHead && !LottoSubList) || LottoSubHead) &&
            Object.keys(RoundData).length > 0
        "
        lg="12"
      >
        <b-card no-body>
          <b-card-body>
            <div class="pl-1 pr-1 pt-1">
              <h3>
                <i
                  class="fab fa-hotjar"
                  style="
                    color: rgb(255, 71, 0);
                    -webkit-text-stroke: 1px rgb(255, 190, 25);
                  "
                />
                หวยรอบ
                <b-badge variant="primary">
                  วันที่ {{ CalNameDate(RoundData.CloseDate) }}
                </b-badge>
              </h3>
            </div>
          </b-card-body>

          <div class="pl-1 pr-1">
            <b-row>
              <b-col lg="4">
                <h5 class="font-weight-bolder">
                  รายการแทง {{ BetListName }} ทั้งหมด
                </h5>
                <span><span class="text-danger">แถบสีแดง</span> = เต็มแล้ว   /   <span
                  class="text-warning"
                >แถบสีส้ม</span>
                  = ใกล้เต็ม (มากกว่า 80 %)
                </span></b-col>
              <b-col lg="8">
                <div>
                  <b-row class="align-items-center justify-content-end">
                    <b-col md="3">
                      <!-- <b-form-group label="จำนวนแถว">
                        <b-form-select
                          v-model="BetListPerpage"
                          :options="pageOptions"
                        />
                      </b-form-group> -->
                    </b-col>
                    <b-col md="3">
                      <!-- <b-form-group label="ประเภท">
                        <b-form-select
                          v-model="BetListName"
                          :options="sortRoundList"
                          @change="FilterBetList()"
                        />
                      </b-form-group> -->
                    </b-col>
                    <b-col md="2">
                      <!-- <b-form-group label="แถบสีขึ้นก่อน">
                        <b-form-checkbox
                          v-model="BetListColor"
                          class="custom-control-danger"
                          name="check-button"
                          switch
                          @change="FilterBetList()"
                        >
                          <span class="switch-icon-left"> เปิด </span>
                          <span class="switch-icon-right"> ปิด </span>
                        </b-form-checkbox>
                      </b-form-group> -->
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="เรียง">
                        <b-form-select
                          v-model="BetListSort"
                          :options="SortList"
                          @change="FilterBetList()"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="1.5">
                      <b-badge variant="primary">
                        รีเฟรชใน {{ DiffRefresh }} วินาที
                      </b-badge>
                    </b-col>
                    <b-col md="2">
                      <b-form-group>
                        <b-button
                          block
                          @click="GetBet(RoundData._id)"
                        >
                          รีเฟรช
                        </b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </div>

          <div style="overflow-x: auto">
            <table
              class="table table-bordered table-sm table-hover table-striped"
            >
              <thead class="thead-dark">
                <tr>
                  <th
                    class="text-center align-middle"
                    style="width: 10rem"
                  />
                  <th
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'u3')"
                    class="text-center"
                  >
                    3 ตัวบน
                  </th>
                  <th
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'd3')"
                    class="text-center"
                  >
                    3 ตัวหน้า
                  </th>
                  <th
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'b3')"
                    class="text-center"
                  >
                    3 ตัวท้าย
                  </th>
                  <th
                    v-if="LottoStatus(LottoHead, LottoSubHead, 't3')"
                    class="text-center"
                  >
                    3 ตัวโต๊ด
                  </th>
                  <th
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'u2')"
                    class="text-center"
                  >
                    2 ตัวบน
                  </th>
                  <th
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'd2')"
                    class="text-center"
                  >
                    2 ตัวล่าง
                  </th>
                  <th
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'u1')"
                    class="text-center"
                  >
                    วิ่งบน
                  </th>
                  <th
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'd1')"
                    class="text-center"
                  >
                    วิ่งล่าง
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="my-1">
                      <b>ซื้อ</b>
                      <span class="float-right ml-2 text-success">
                        {{
                          Commas(( BetDetail.U3.buy +
                            BetDetail.D3.buy +
                            BetDetail.B3.buy +
                            BetDetail.T3.buy +
                            BetDetail.U2.buy +
                            BetDetail.D2.buy +
                            BetDetail.T2.buy +
                            BetDetail.U1.buy +
                            BetDetail.D1.buy).toFixed(2)

                          )
                        }}
                      </span>
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'u3')"
                    class="text-right"
                  >
                    <div class="text-success">
                      {{ Commas(BetDetail.U3.buy.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'd3')"
                    class="text-right"
                  >
                    <div class="text-success">
                      {{ Commas(BetDetail.D3.buy.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'b3')"
                    class="text-right"
                  >
                    <div class="text-success">
                      {{ Commas(BetDetail.B3.buy.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 't3')"
                    class="text-right"
                  >
                    <div class="text-success">
                      {{ Commas(BetDetail.T3.buy.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'u2')"
                    class="text-right"
                  >
                    <div class="text-success">
                      {{ Commas(BetDetail.U2.buy.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'd2')"
                    class="text-right"
                  >
                    <div class="text-success">
                      {{ Commas(BetDetail.D2.buy.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'u1')"
                    class="text-right"
                  >
                    <div class="text-success">
                      {{ Commas(BetDetail.U1.buy.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'd1')"
                    class="text-right"
                  >
                    <div class="text-success">
                      {{ Commas(BetDetail.D1.buy.toFixed(2)) }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="my-1">
                      <b>คอม</b>
                      <span class="float-right ml-2 text-danger">
                        {{
                          Commas(( BetDetail.U3.com +
                            BetDetail.D3.com +
                            BetDetail.B3.com +
                            BetDetail.T3.com +
                            BetDetail.U2.com +
                            BetDetail.D2.com +
                            BetDetail.T2.com +
                            BetDetail.U1.com +
                            BetDetail.D1.com).toFixed(2)
                          )
                        }}
                      </span>
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'u3')"
                    class="text-right"
                  >
                    <div class="text-danger">
                      {{ Commas(BetDetail.U3.com.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'd3')"
                    class="text-right"
                  >
                    <div class="text-danger">
                      {{ Commas(BetDetail.D3.com.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'b3')"
                    class="text-right"
                  >
                    <div class="text-danger">
                      {{ Commas(BetDetail.B3.com.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 't3')"
                    class="text-right"
                  >
                    <div class="text-danger">
                      {{ Commas(BetDetail.T3.com.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'u2')"
                    class="text-right"
                  >
                    <div class="text-danger">
                      {{ Commas(BetDetail.U2.com.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'd2')"
                    class="text-right"
                  >
                    <div class="text-danger">
                      {{ Commas(BetDetail.D2.com.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'u1')"
                    class="text-right"
                  >
                    <div class="text-danger">
                      {{ Commas(BetDetail.U1.com.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'd1')"
                    class="text-right"
                  >
                    <div class="text-danger">
                      {{ Commas(BetDetail.D1.com.toFixed(2)) }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="my-1">
                      <b>รับ</b>
                      <span class="float-right ml-2 text-success">
                        {{
                          Commas(( BetDetail.U3.revenue +
                            BetDetail.D3.revenue +
                            BetDetail.B3.revenue +
                            BetDetail.T3.revenue +
                            BetDetail.U2.revenue +
                            BetDetail.D2.revenue +
                            BetDetail.T2.revenue +
                            BetDetail.U1.revenue +
                            BetDetail.D1.revenue).toFixed(2)

                          )
                        }}
                      </span>
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'u3')"
                    class="text-right"
                  >
                    <div class="text-success">
                      {{ Commas(BetDetail.U3.revenue.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'd3')"
                    class="text-right"
                  >
                    <div class="text-success">
                      {{ Commas(BetDetail.D3.revenue.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'b3')"
                    class="text-right"
                  >
                    <div class="text-success">
                      {{ Commas(BetDetail.B3.revenue.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 't3')"
                    class="text-right"
                  >
                    <div class="text-success">
                      {{ Commas(BetDetail.T3.revenue.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'u2')"
                    class="text-right"
                  >
                    <div class="text-success">
                      {{ Commas(BetDetail.U2.revenue.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'd2')"
                    class="text-right"
                  >
                    <div class="text-success">
                      {{ Commas(BetDetail.D2.revenue.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'u1')"
                    class="text-right"
                  >
                    <div class="text-success">
                      {{ Commas(BetDetail.U1.revenue.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'd1')"
                    class="text-right"
                  >
                    <div class="text-success">
                      {{ Commas(BetDetail.D1.revenue.toFixed(2)) }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="my-1">
                      <b>จ่าย</b>
                      <span class="float-right ml-2 text-danger">
                        {{
                          Commas(
                            BetDetail.U3.pay +
                              BetDetail.D3.pay +
                              BetDetail.B3.pay +
                              BetDetail.T3.pay +
                              BetDetail.U2.pay +
                              BetDetail.D2.pay +
                              BetDetail.T2.pay +
                              BetDetail.U1.pay +
                              BetDetail.D1.pay,
                          )
                        }}
                      </span>
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'u3')"
                    class="text-right"
                  >
                    <div class="text-danger">
                      {{ Commas(BetDetail.U3.pay.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'd3')"
                    class="text-right"
                  >
                    <div class="text-danger">
                      {{ Commas(BetDetail.D3.pay.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'b3')"
                    class="text-right"
                  >
                    <div class="text-danger">
                      {{ Commas(BetDetail.B3.pay.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 't3')"
                    class="text-right"
                  >
                    <div class="text-danger">
                      {{ Commas(BetDetail.T3.pay.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'u2')"
                    class="text-right"
                  >
                    <div class="text-danger">
                      {{ Commas(BetDetail.U2.pay.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'd2')"
                    class="text-right"
                  >
                    <div class="text-danger">
                      {{ Commas(BetDetail.D2.pay.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'u1')"
                    class="text-right"
                  >
                    <div class="text-danger">
                      {{ Commas(BetDetail.U1.pay.toFixed(2)) }}
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'd1')"
                    class="text-right"
                  >
                    <div class="text-danger">
                      {{ Commas(BetDetail.D1.pay.toFixed(2)) }}
                    </div>
                  </td>
                </tr>
                <tr class="table-warning">
                  <td>
                    <div class="my-1">
                      <b>ตั้งสู้</b>
                      <span class="float-right ml-2">
                        <button
                          class="btn btn-primary btn-sm"
                          @click="SubmitRate()"
                        >
                          <i
                            class="fal fa-save"
                          /> บันทึก
                        </button>
                      </span>
                    </div>
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'u3')"
                    class="text-right"
                  >
                    <input
                      v-model="RateInput.BetLimitU3"
                      type="number"
                      class="form-control text-right form-control"
                      @input="
                        RateInput.PayLimitU3 =
                          RateInput.BetLimitU3 * RateInput.U3
                      "
                    >
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'd3')"
                    class="text-right"
                  >
                    <input
                      v-model="RateInput.BetLimitD3"
                      type="number"
                      class="form-control text-right form-control"
                      @input="
                        RateInput.PayLimitD3 =
                          RateInput.BetLimitD3 * RateInput.D3
                      "
                    >
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'b3')"
                    class="text-right"
                  >
                    <input
                      v-model="RateInput.BetLimitB3"
                      type="number"
                      class="form-control text-right form-control"
                      @input="
                        RateInput.PayLimitB3 =
                          RateInput.BetLimitB3 * RateInput.B3
                      "
                    >
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 't3')"
                    class="text-right"
                  >
                    <input
                      v-model="RateInput.BetLimitT3"
                      type="number"
                      class="form-control text-right form-control"
                      @input="
                        RateInput.PayLimitT3 =
                          RateInput.BetLimitT3 * RateInput.T3
                      "
                    >
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'u2')"
                    class="text-right"
                  >
                    <input
                      v-model="RateInput.BetLimitU2"
                      type="number"
                      class="form-control text-right form-control"
                      @input="
                        RateInput.PayLimitU2 =
                          RateInput.BetLimitU2 * RateInput.U2
                      "
                    >
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'd2')"
                    class="text-right"
                  >
                    <input
                      v-model="RateInput.BetLimitD2"
                      type="number"
                      class="form-control text-right form-control"
                      @input="
                        RateInput.PayLimitD2 =
                          RateInput.BetLimitD2 * RateInput.D2
                      "
                    >
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'u1')"
                    class="text-right"
                  >
                    <input
                      v-model="RateInput.BetLimitU1"
                      type="number"
                      class="form-control text-right form-control"
                      @input="
                        RateInput.PayLimitU1 =
                          RateInput.BetLimitU1 * RateInput.U1
                      "
                    >
                  </td>
                  <td
                    v-if="LottoStatus(LottoHead, LottoSubHead, 'd1')"
                    class="text-right"
                  >
                    <input
                      v-model="RateInput.BetLimitD1"
                      type="number"
                      class="form-control text-right form-control"
                      @input="
                        RateInput.PayLimitD1 =
                          RateInput.BetLimitD1 * RateInput.D1
                      "
                    >
                  </td>
                </tr>

                <!-- ******** -->
                <tr
                  v-for="index in BetReportMax"
                  :key="index"
                >
                  <td class="text-center">
                    <div>{{ index }}</div>
                  </td>
                  <td
                    v-if="BetReportArry.U3[index - 1]"
                    :class="ColorReport(BetReportArry.U3[index - 1].totalAmount , RateInput.BetLimitU3)"
                  >
                    <span
                      class="badge badge-soft-secondary tx-14 badge-secondary"
                    >{{ BetReportArry.U3[index - 1]._id }}</span>
                    <span class="float-right ml-2 text-primary">{{ Commas(BetReportArry.U3[index - 1].totalAmount) }}</span><br>
                    <div style="margin-top: 5px">
                      <small class="tx-10">คาดคะเนยอดเสีย</small>
                      <small class="float-right ml-2 text-danger">{{ Commas(BetReportArry.U3[index - 1].totalPay) }}</small>
                    </div>
                  </td>
                  <td v-else-if="LottoStatus(LottoHead, LottoSubHead, 'u3')">
                    <span
                      class="badge badge-soft-secondary tx-14 badge-secondary"
                    />
                    <span class="float-right ml-2" /><br>
                    <div style="margin-top: 5px">
                      <small class="tx-10" />
                      <small class="float-right ml-2 text-danger" />
                    </div>
                  </td>
                  <td
                    v-if="BetReportArry.D3[index - 1]"
                    :class="ColorReport(BetReportArry.D3[index - 1].totalAmount , RateInput.BetLimitD3)"
                  >
                    <span
                      class="badge badge-soft-secondary tx-14 badge-secondary"
                    >{{ BetReportArry.D3[index - 1]._id }}</span>
                    <span class="float-right ml-2 text-primary">{{ Commas(BetReportArry.D3[index - 1].totalAmount) }}</span><br>
                    <div style="margin-top: 5px">
                      <small class="tx-10">คาดคะเนยอดเสีย</small>
                      <small class="float-right ml-2 text-danger">{{ Commas(BetReportArry.D3[index - 1].totalPay) }}</small>
                    </div>
                  </td>
                  <td v-else-if="LottoStatus(LottoHead, LottoSubHead, 'd3')">
                    <span
                      class="badge badge-soft-secondary tx-14 badge-secondary"
                    />
                    <span class="float-right ml-2" /><br>
                    <div style="margin-top: 5px">
                      <small class="tx-10" />
                      <small class="float-right ml-2 text-danger" />
                    </div>
                  </td>
                  <td
                    v-if="BetReportArry.B3[index - 1]"
                    :class="ColorReport(BetReportArry.B3[index - 1].totalAmount , RateInput.BetLimitB3)"
                  >
                    <span
                      class="badge badge-soft-secondary tx-14 badge-secondary"
                    >{{ BetReportArry.B3[index - 1]._id }}</span>
                    <span class="float-right ml-2 text-primary">{{ Commas(BetReportArry.B3[index - 1].totalAmount) }}</span><br>
                    <div style="margin-top: 5px">
                      <small class="tx-10">คาดคะเนยอดเสีย</small>
                      <small class="float-right ml-2 text-danger">{{ Commas(BetReportArry.B3[index - 1].totalPay) }}</small>
                    </div>
                  </td>
                  <td v-else-if="LottoStatus(LottoHead, LottoSubHead, 'b3')">
                    <span
                      class="badge badge-soft-secondary tx-14 badge-secondary"
                    />
                    <span class="float-right ml-2" /><br>
                    <div style="margin-top: 5px">
                      <small class="tx-10" />
                      <small class="float-right ml-2 text-danger" />
                    </div>
                  </td>
                  <td
                    v-if="BetReportArry.T3[index - 1]"
                    :class="ColorReport(BetReportArry.T3[index - 1].totalAmount , RateInput.BetLimitT3)"
                  >
                    <span
                      class="badge badge-soft-secondary tx-14 badge-secondary"
                    >{{ BetReportArry.T3[index - 1]._id }}</span>
                    <span class="float-right ml-2 text-primary">{{ Commas(BetReportArry.T3[index - 1].totalAmount) }}</span><br>
                    <div style="margin-top: 5px">
                      <small class="tx-10">คาดคะเนยอดเสีย</small>
                      <small class="float-right ml-2 text-danger">{{ Commas(BetReportArry.T3[index - 1].totalPay) }}</small>
                    </div>
                  </td>
                  <td v-else-if="LottoStatus(LottoHead, LottoSubHead, 't3')">
                    <span
                      class="badge badge-soft-secondary tx-14 badge-secondary"
                    />
                    <span class="float-right ml-2" /><br>
                    <div style="margin-top: 5px">
                      <small class="tx-10" />
                      <small class="float-right ml-2 text-danger" />
                    </div>
                  </td>
                  <td
                    v-if="BetReportArry.U2[index - 1]"
                    :class="ColorReport(BetReportArry.U2[index - 1].totalAmount , RateInput.BetLimitU2)"
                  >
                    <span
                      class="badge badge-soft-secondary tx-14 badge-secondary"
                    >{{ BetReportArry.U2[index - 1]._id }}</span>
                    <span class="float-right ml-2 text-primary">{{ Commas(BetReportArry.U2[index - 1].totalAmount) }}</span><br>
                    <div style="margin-top: 5px">
                      <small class="tx-10">คาดคะเนยอดเสีย</small>
                      <small class="float-right ml-2 text-danger">{{ Commas(BetReportArry.U2[index - 1].totalPay) }}</small>
                    </div>
                  </td>
                  <td v-else-if="LottoStatus(LottoHead, LottoSubHead, 'u2')">
                    <span
                      class="badge badge-soft-secondary tx-14 badge-secondary"
                    />
                    <span class="float-right ml-2" /><br>
                    <div style="margin-top: 5px">
                      <small class="tx-10" />
                      <small class="float-right ml-2 text-danger" />
                    </div>
                  </td>
                  <td
                    v-if="BetReportArry.D2[index - 1]"
                    :class="ColorReport(BetReportArry.D2[index - 1].totalAmount , RateInput.BetLimitD2)"
                  >
                    <span
                      class="badge badge-soft-secondary tx-14 badge-secondary"
                    >{{ BetReportArry.D2[index - 1]._id }}</span>
                    <span class="float-right ml-2 text-primary">{{ Commas(BetReportArry.D2[index - 1].totalAmount) }}</span><br>
                    <div style="margin-top: 5px">
                      <small class="tx-10">คาดคะเนยอดเสีย</small>
                      <small class="float-right ml-2 text-danger">{{ Commas(BetReportArry.D2[index - 1].totalPay) }}</small>
                    </div>
                  </td>
                  <td v-else-if="LottoStatus(LottoHead, LottoSubHead, 'd2')">
                    <span
                      class="badge badge-soft-secondary tx-14 badge-secondary"
                    />
                    <span class="float-right ml-2" /><br>
                    <div style="margin-top: 5px">
                      <small class="tx-10" />
                      <small class="float-right ml-2 text-danger" />
                    </div>
                  </td>
                  <td
                    v-if="BetReportArry.U1[index - 1]"
                    :class="ColorReport(BetReportArry.U1[index - 1].totalAmount , RateInput.BetLimitU1)"
                  >
                    <span
                      class="badge badge-soft-secondary tx-14 badge-secondary"
                    >{{ BetReportArry.U1[index - 1]._id }}</span>
                    <span class="float-right ml-2 text-primary">{{ Commas(BetReportArry.U1[index - 1].totalAmount) }}</span><br>
                    <div style="margin-top: 5px">
                      <small class="tx-10">คาดคะเนยอดเสีย</small>
                      <small class="float-right ml-2 text-danger">{{ Commas(BetReportArry.U1[index - 1].totalPay) }}</small>
                    </div>
                  </td>
                  <td v-else-if="LottoStatus(LottoHead, LottoSubHead, 'u1')">
                    <span
                      class="badge badge-soft-secondary tx-14 badge-secondary"
                    />
                    <span class="float-right ml-2" /><br>
                    <div style="margin-top: 5px">
                      <small class="tx-10" />
                      <small class="float-right ml-2 text-danger" />
                    </div>
                  </td>
                  <td
                    v-if="BetReportArry.D1[index - 1]"
                    :class="ColorReport(BetReportArry.D1[index - 1].totalAmount , RateInput.BetLimitD1)"
                  >
                    <span
                      class="badge badge-soft-secondary tx-14 badge-secondary"
                    >{{ BetReportArry.D1[index - 1]._id }}</span>
                    <span class="float-right ml-2 text-primary">{{ Commas(BetReportArry.D1[index - 1].totalAmount) }}</span><br>
                    <div style="margin-top: 5px">
                      <small class="tx-10">คาดคะเนยอดเสีย</small>
                      <small class="float-right ml-2 text-danger">{{ Commas(BetReportArry.D1[index - 1].totalPay) }}</small>
                    </div>
                  </td>
                  <td v-else-if="LottoStatus(LottoHead, LottoSubHead, 'd1')">
                    <span
                      class="badge badge-soft-secondary tx-14 badge-secondary"
                    />
                    <span class="float-right ml-2" /><br>
                    <div style="margin-top: 5px">
                      <small class="tx-10" />
                      <small class="float-right ml-2 text-danger" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>

      <!-- modal -->
      <b-modal
        v-model="MdRate"
        ok-title="บันทึก"
        ok-variant="success"
        cancel-title="ยกเลิก"
        cancel-variant="outline-danger"
        title="ตั้งสู้"
        @ok.prevent="validationBet"
      >
        <b-table
          responsive="sm"
          class="position-relative items-center"
          per-page="5"
          :current-page="currentPageLimitItems"
          :fields="FieldsMdLimit"
          :items="MdLimitItems"
        >
          <template #cell(index)="data">
            {{ 5 * (currentPageLimitItems - 1) + (data.index + 1) }}
          </template>
          <template #foot(type)="data">
            <span class="text-danger">{{ data.label }}</span>
          </template>

          <template #foot()="data">
            <i>{{ data.label }}</i>
          </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPageLimitItems"
              :total-rows="MdLimitItems.length"
              per-page="5"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              lass="mb-1 ml-1"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BPagination,
  BTable,
  BBadge,
  // BInputGroupPrepend,
  // BFormSelect,
  // BFormDatepicker,
  // BFormTimepicker,
  BOverlay,
  BIconController,
  BFormSelect,
  // BFormCheckbox,
} from 'bootstrap-vue'
import momenttz from 'moment-timezone'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, integer, between, length, min,
} from '@validations'

export default {
  components: {
    vSelect,
    // BInputGroupPrepend,
    // BFormSelect,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BPagination,
    BTable,
    BBadge,
    // BFormDatepicker,
    // BFormTimepicker,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BIconController,
    BFormSelect,
    // BFormCheckbox,
  },
  data() {
    return {
      UserData: JSON.parse(localStorage.getItem('userData')),
      min,
      required,
      integer,
      between,
      length,
      RoundList: [
        { value: 'รวม', text: 'รวม', code: 'รวม' },
        { value: '3 ตัวบน', text: '3 ตัวบน', code: 'u3' },
        { value: '3 ตัวหน้า', text: '3 ตัวหน้า', code: 'd3' },
        { value: '3 ตัวท้าย', text: '3 ตัวท้าย', code: 'b3' },
        { value: '3 ตัวโต๊ด', text: '3 ตัวโต๊ด', code: 't3' },
        { value: '2 ตัวบน', text: '2 ตัวบน', code: 'u2' },
        { value: '2 ตัวล่าง', text: '2 ตัวล่าง', code: 'd2' },
        { value: '2 ตัวโต๊ด(บน)', text: '2 ตัวโต๊ด(บน)', code: 't2' },
        { value: 'วิ่งบน', text: 'วิ่งบน', code: 'u1' },
        { value: 'วิ่งล่าง', text: 'วิ่งล่าง', code: 'd1' },
      ],
      SortList: [
        { value: 1, text: 'คาดคะเนยอดซื้อมาก-น้อย' },
        { value: 2, text: 'คาดคะเนยอดซื้อน้อย-มาก' },
        { value: 3, text: 'คาดคะเนยอดเสียมาก-น้อย' },
        { value: 4, text: 'คาดคะเนยอดเสียน้อย-มาก' },
      ],
      BetListName: null,
      BetListPerpage: 50,
      BetListSort: 1,
      BetListColor: false,
      ShowRound: false,
      show: true,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      pageOptions: [50, 100, 200, 250],
      perPage: 5,
      fields: [
        { key: 'type', label: '#' },
        { key: 'buy', label: 'ซื้อ' },
        { key: 'commission', label: 'คอม' },
        { key: 'receive', label: 'รับ' },
        { key: 'pay', label: 'จ่าย' },
        //  { key: 'pay', label: 'จ่าย' },
      ],
      items: [],
      MdRate: false,
      FieldsMdLimit: [
        { key: 'index', label: 'ลำดับที่' },
        { key: 'name_type', label: 'ประเภท' },
        { key: '_id', label: 'เลข' },
        { key: 'totalAmount', label: 'ซื้อ' },
        { key: 'totalRate', label: 'อัตราจ่ายเฉลี่ย' },
        { key: 'totalPay', label: 'จ่าย' },
        { key: 'count', label: 'จำนวน' },
      ],
      MdLimitItems: [],
      currentPageLimitItems: 1,
      LottoHead: null,
      LottoSubHead: null,
      LottoList: JSON.parse(localStorage.getItem('LottoList')),
      LottoSubList: null,
      StatusResult: true,
      RoundData: {},
      RateData: {},
      RateInput: {
        LottoHead: null,
        LottoSubHead: null,
        RoundID: null,
        U3: 0,
        D3: 0,
        B3: 0,
        T3: 0,
        U2: 0,
        D2: 0,
        T2: 0,
        U1: 0,
        D1: 0,
        BetLimitU3: 0,
        BetLimitD3: 0,
        BetLimitB3: 0,
        BetLimitT3: 0,
        BetLimitU2: 0,
        BetLimitD2: 0,
        BetLimitT2: 0,
        BetLimitU1: 0,
        BetLimitD1: 0,
        PayLimitU3: 0,
        PayLimitD3: 0,
        PayLimitB3: 0,
        PayLimitT3: 0,
        PayLimitU2: 0,
        PayLimitD2: 0,
        PayLimitT2: 0,
        PayLimitU1: 0,
        PayLimitD1: 0,
      },
      BetData: {
        U3: [],
        D3: [],
        B3: [],
        T3: [],
        U2: [],
        D2: [],
        T2: [],
        U1: [],
        D1: [],
      },
      BetDetail: {
        U3: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        D3: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        B3: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        T3: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        U2: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        D2: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        T2: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        U1: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        D1: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
      },
      BetList: [],
      Interval: null,
      TimeToRefresh: 60,
      TimeRefresh: momenttz().add(this.TimeToRefresh + 1, 'seconds'),
      DiffRefresh: 0,
      BetReportArry: null,
      BetReportMax: 0,
    }
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    sortRoundList() {
      return this.RoundList.filter(
        f => this.LottoStatus(this.LottoHead, this.LottoSubHead, f.code)
          || f.code === 'รวม',
      ).map(f => ({ text: f.text, value: f.value, code: f.code }))
    },
  },
  beforeDestroy() {
    clearInterval(this.Interval)
  },
  async created() {
    this.LottoList = await this.GetSublist()
  },
  mounted() {
    this.totalRows = this.items.length
    // this.getRound()
  },
  methods: {
    // eslint-disable-next-line consistent-return
    async GetSublist() {
      const List = []
      try {
        const { data: Res } = await this.$http.get(
          'https://api.rmtlotto.com/api/LottoList',
        )
        // eslint-disable-next-line
        for (const x in Res) {
          let SubList = null
          if (Res[x].Subhead.length > 0) {
            SubList = []
            // eslint-disable-next-line
            for (const c in Res[x].Subhead) {
              SubList.push({
                text: Res[x].Subhead[c].name,
                value: Res[x].Subhead[c].SubHeadID,
                Data: Res[x].Subhead[c],
              })
            }
          }
          List.push({
            text: Res[x].name,
            value: Res[x].HeadID,
            SubList,
            Data: Res[x],
          })
        }
        return List
      } catch (e) {
        console.log(e)
      }
    },
    LottoStatus(Head, Sub, Val) {
      if (!Head) return false
      const IndexHead = this.LottoList.findIndex(({ value }) => value === Head)
      if (IndexHead >= 0) {
        if (this.LottoList[IndexHead].SubList && Sub) {
          const IndexSub = this.LottoList[IndexHead].SubList.findIndex(
            ({ value }) => value === Sub,
          )
          if (IndexSub >= 0) {
            return this.LottoList[IndexHead].SubList[IndexSub].Data[Val] === 1
          }
        } else if (!this.LottoList[IndexHead].SubList) {
          return this.LottoList[IndexHead].Data[Val] === 1
        } else {
          return false
        }
      }
      return false
    },
    validationBet() {
      this.$refs.validBet.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.SetBet();
        }
      })
    },
    showModal() {
      this.MdRate = true
    },
    CheckRound() {
      if ((this.LottoHead && !this.LottoSubList) || this.LottoSubHead) {
        this.GetRound(this.LottoHead, this.LottoSubHead)
      }
    },
    GetRound(LottoHead, LottoSubHead) {
      this.ShowRound = true
      const params = {
        LottoHead,
        LottoSubHead,
      }
      this.$http
        .get(
          'https://api.rmtlotto.com/api/round/ShowByHeadAndSub',
          {
            params,
          },
        )
        .then(response => {
          const ResData = response.data
          if (ResData.success) {
            this.RoundData = ResData.mes
            // eslint-disable-next-line no-underscore-dangle
            this.GetRate(this.RoundData._id)
            // eslint-disable-next-line no-underscore-dangle
            this.GetBet(this.RoundData._id)
            this.StartRefresh()
          }
        })
        .catch(error => {
          console.log(error)
          this.ShowRound = false
        })
    },
    GetRate(ID) {
      this.ShowRound = true
      const params = {
        ID,
      }
      this.$http
        .get(
          'https://api.rmtlotto.com/api/ratebyround/ShowByRoundID',
          {
            params,
          },
        )
        .then(response => {
          const ResData = response.data
          if (ResData.success) {
            this.RateData = ResData.mes
            this.CalRate(this.RateData)
          }
          // this.ShowRound = false
        })
        .catch(error => {
          console.log(error)
          this.ShowRound = false
        })
    },
    GetBet(RoundID) {
      this.ShowRound = true
      this.TimeRefresh = momenttz().add(this.TimeToRefresh + 1, 'seconds')
      const params = {
        RoundID,
      }
      this.$http
        .get(
          'https://api.rmtlotto.com/api/betback/SumByRoundID/Collection',
          {
            params,
          },
        )
        .then(response => {
          const ResData = response.data
          if (ResData.success) {
            this.ShowRound = false
            this.BetData = ResData.mes
            this.Betreport(ResData.mes)
            this.CalBetDetail(this.BetData)
            if (!this.BetListName) {
              this.BetList = [
                ...this.BetData.U3,
                ...this.BetData.D3,
                ...this.BetData.B3,
                ...this.BetData.T3,
                ...this.BetData.U2,
                ...this.BetData.D2,
                ...this.BetData.T2,
                ...this.BetData.U1,
                ...this.BetData.D1,
              ]
              this.BetListName = 'รวม'
              this.SortBetDeatail(this.BetList, '_id', 'asc')
              this.SortBetDeatail(this.BetList, 'totalAmount', 'desc')
            } else {
              this.FilterBetList()
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    Betreport(data) {
      this.BetReportArry = data
      // console.log(this.BetReportArry)
      const U3 = data.U3.length
      const D3 = data.D3.length
      const B3 = data.B3.length
      const T3 = data.T3.length
      const U2 = data.U2.length
      const D2 = data.D2.length
      const T2 = data.T2.length
      const U1 = data.U1.length
      const D1 = data.D1.length
      this.BetReportMax = Math.max(U3, D3, B3, T3, U2, D2, T2, U1, D1)
    },
    SubmitRate() {
      this.$http
        .post(
          'https://api.rmtlotto.com/api/ratebyround/store',
          this.RateInput,
        )
        .then(response => {
          const ResData = response.data
          if (ResData.success) {
            this.RateData = ResData.mes
            this.CalRate(this.RateData)
            this.Success('บันทึกการตั้งสู้สำเร็จ')
          }
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    FilterBetList() {
      this.SortBetDeatail(this.BetReportArry, '_id', 'asc')
      if (this.BetListSort === 1) {
        this.SortBetDeatail(this.BetReportArry, 'totalAmount', 'desc')
      } else if (this.BetListSort === 2) {
        this.SortBetDeatail(this.BetReportArry, 'totalAmount', 'asc')
      } else if (this.BetListSort === 3) {
        this.SortBetDeatail(this.BetReportArry, 'totalPay', 'desc')
      } else if (this.BetListSort === 4) {
        this.SortBetDeatail(this.BetReportArry, 'totalPay', 'asc')
      }
    },
    CalRate(RateData) {
      this.RateInput.LottoHead = RateData.LottoHead
      this.RateInput.LottoSubHead = RateData.LottoSubHead
      this.RateInput.RoundID = RateData.RoundID
      this.RateInput.U3 = RateData.U3
      this.RateInput.D3 = RateData.D3
      this.RateInput.B3 = RateData.B3
      this.RateInput.T3 = RateData.T3
      this.RateInput.U2 = RateData.U2
      this.RateInput.D2 = RateData.D2
      this.RateInput.T2 = RateData.T2
      this.RateInput.U1 = RateData.U1
      this.RateInput.D1 = RateData.D1
      this.RateInput.BetLimitU3 = RateData.BetLimitU3
      this.RateInput.BetLimitD3 = RateData.BetLimitD3
      this.RateInput.BetLimitB3 = RateData.BetLimitB3
      this.RateInput.BetLimitT3 = RateData.BetLimitT3
      this.RateInput.BetLimitU2 = RateData.BetLimitU2
      this.RateInput.BetLimitD2 = RateData.BetLimitD2
      this.RateInput.BetLimitT2 = RateData.BetLimitT2
      this.RateInput.BetLimitU1 = RateData.BetLimitU1
      this.RateInput.BetLimitD1 = RateData.BetLimitD1
      this.RateInput.PayLimitU3 = RateData.BetLimitU3 * RateData.U3
      this.RateInput.PayLimitD3 = RateData.BetLimitD3 * RateData.D3
      this.RateInput.PayLimitB3 = RateData.BetLimitB3 * RateData.B3
      this.RateInput.PayLimitT3 = RateData.BetLimitT3 * RateData.T3
      this.RateInput.PayLimitU2 = RateData.BetLimitU2 * RateData.U2
      this.RateInput.PayLimitD2 = RateData.BetLimitD2 * RateData.D2
      this.RateInput.PayLimitT2 = RateData.BetLimitT2 * RateData.T2
      this.RateInput.PayLimitU1 = RateData.BetLimitU1 * RateData.U1
      this.RateInput.PayLimitD1 = RateData.BetLimitD1 * RateData.D1
    },
    CalBetDetail(BetData) {
      this.BetDetail.U3.buy = this.SumByKey(BetData.U3, 'totalAmount')
      this.BetDetail.U3.pay = this.CalMax(BetData.U3, 'totalPay')
      this.BetDetail.D3.buy = this.SumByKey(BetData.D3, 'totalAmount')
      this.BetDetail.D3.pay = this.CalMax(BetData.D3, 'totalPay')
      this.BetDetail.B3.buy = this.SumByKey(BetData.B3, 'totalAmount')
      this.BetDetail.B3.pay = this.CalMax(BetData.B3, 'totalPay')
      this.BetDetail.T3.buy = this.SumByKey(BetData.T3, 'totalAmount')
      this.BetDetail.T3.pay = this.CalMax(BetData.T3, 'totalPay')
      this.BetDetail.U2.buy = this.SumByKey(BetData.U2, 'totalAmount')
      this.BetDetail.U2.pay = this.CalMax(BetData.U2, 'totalPay')
      this.BetDetail.D2.buy = this.SumByKey(BetData.D2, 'totalAmount')
      this.BetDetail.D2.pay = this.CalMax(BetData.D2, 'totalPay')
      this.BetDetail.T2.buy = this.SumByKey(BetData.T2, 'totalAmount')
      this.BetDetail.T2.pay = this.CalMax(BetData.T2, 'totalPay')
      this.BetDetail.U1.buy = this.SumByKey(BetData.U1, 'totalAmount')
      this.BetDetail.U1.pay = this.CalMax(BetData.U1, 'totalPay')
      this.BetDetail.D1.buy = this.SumByKey(BetData.D1, 'totalAmount')
      this.BetDetail.D1.pay = this.CalMax(BetData.D1, 'totalPay')
      this.BetDetail.U3.com = (this.BetDetail.U3.buy * this.UserData.discount) / 100
      this.BetDetail.D3.com = (this.BetDetail.D3.buy * this.UserData.discount) / 100
      this.BetDetail.B3.com = (this.BetDetail.B3.buy * this.UserData.discount) / 100
      this.BetDetail.T3.com = (this.BetDetail.T3.buy * this.UserData.discount) / 100
      this.BetDetail.U2.com = (this.BetDetail.U2.buy * this.UserData.discount) / 100
      this.BetDetail.D2.com = (this.BetDetail.D2.buy * this.UserData.discount) / 100
      this.BetDetail.T2.com = (this.BetDetail.T2.buy * this.UserData.discount) / 100
      this.BetDetail.U1.com = (this.BetDetail.U1.buy * this.UserData.discount) / 100
      this.BetDetail.D1.com = (this.BetDetail.D1.buy * this.UserData.discount) / 100
      this.BetDetail.U3.revenue = this.BetDetail.U3.buy - this.BetDetail.U3.com
      this.BetDetail.D3.revenue = this.BetDetail.D3.buy - this.BetDetail.D3.com
      this.BetDetail.B3.revenue = this.BetDetail.B3.buy - this.BetDetail.B3.com
      this.BetDetail.T3.revenue = this.BetDetail.T3.buy - this.BetDetail.T3.com
      this.BetDetail.U2.revenue = this.BetDetail.U2.buy - this.BetDetail.U2.com
      this.BetDetail.D2.revenue = this.BetDetail.D2.buy - this.BetDetail.D2.com
      this.BetDetail.T2.revenue = this.BetDetail.T2.buy - this.BetDetail.T2.com
      this.BetDetail.U1.revenue = this.BetDetail.U1.buy - this.BetDetail.U1.com
      this.BetDetail.D1.revenue = this.BetDetail.D1.buy - this.BetDetail.D1.com
      // this.BetDetail.U3.balance = this.BetDetail.U3.revenue - this.BetDetail.U3.pay
      // this.BetDetail.D3.balance = this.BetDetail.D3.revenue - this.BetDetail.D3.pay
      // this.BetDetail.B3.balance = this.BetDetail.B3.revenue - this.BetDetail.B3.pay
      // this.BetDetail.T3.balance = this.BetDetail.T3.revenue - this.BetDetail.T3.pay
      // this.BetDetail.U2.balance = this.BetDetail.U2.revenue - this.BetDetail.U2.pay
      // this.BetDetail.D2.balance = this.BetDetail.D2.revenue - this.BetDetail.D2.pay
      // this.BetDetail.T2.balance = this.BetDetail.T2.revenue - this.BetDetail.T2.pay
      // this.BetDetail.U1.balance = this.BetDetail.U1.revenue - this.BetDetail.U1.pay
      // this.BetDetail.D1.balance = this.BetDetail.D1.revenue - this.BetDetail.D1.pay
      // if (this.BetDetail.U3.pay) {
      //   const InU3 = BetData.U3.findIndex(
      //     ({ totalPay }) => totalPay === this.BetDetail.U3.pay,
      //   )
      //   this.BetDetail.U3.revenue = this.BetDetail.U3.buy - BetData.U3[InU3].totalAmount
      // }
      // if (this.BetDetail.D3.pay) {
      //   const InD3 = BetData.D3.findIndex(
      //     ({ totalPay }) => totalPay === this.BetDetail.D3.pay,
      //   )
      //   this.BetDetail.D3.revenue = this.BetDetail.D3.buy - BetData.D3[InD3].totalAmount
      // }
      // if (this.BetDetail.T3.pay) {
      //   const InT3 = BetData.T3.findIndex(
      //     ({ totalPay }) => totalPay === this.BetDetail.T3.pay,
      //   )
      //   this.BetDetail.T3.revenue = this.BetDetail.T3.buy - BetData.T3[InT3].totalAmount
      // }
      // if (this.BetDetail.U2.pay) {
      //   const InU2 = BetData.U2.findIndex(
      //     ({ totalPay }) => totalPay === this.BetDetail.U2.pay,
      //   )
      //   this.BetDetail.U2.revenue = this.BetDetail.U2.buy - BetData.U2[InU2].totalAmount
      // }
      // if (this.BetDetail.D2.pay) {
      //   const InD2 = BetData.D2.findIndex(
      //     ({ totalPay }) => totalPay === this.BetDetail.D2.pay,
      //   )
      //   this.BetDetail.D2.revenue = this.BetDetail.D2.buy - BetData.D2[InD2].totalAmount
      // }
      // if (this.BetDetail.T2.pay) {
      //   const InT2 = BetData.T2.findIndex(
      //     ({ totalPay }) => totalPay === this.BetDetail.T2.pay,
      //   )
      //   this.BetDetail.T2.revenue = this.BetDetail.T2.buy - BetData.T2[InT2].totalAmount
      // }
      // if (this.BetDetail.U1.pay) {
      //   const InU1 = BetData.U1.findIndex(
      //     ({ totalPay }) => totalPay === this.BetDetail.U1.pay,
      //   )
      //   this.BetDetail.U1.revenue = this.BetDetail.U1.buy - BetData.U1[InU1].totalAmount
      // }
      // if (this.BetDetail.D1.pay) {
      //   const InD1 = BetData.D1.findIndex(
      //     ({ totalPay }) => totalPay === this.BetDetail.D1.pay,
      //   )
      //   this.BetDetail.D1.revenue = this.BetDetail.D1.buy - BetData.D1[InD1].totalAmount
      // }
    },
    SortBetDeatail(Arr, Key, type) {
      if (type === 'asc') {
        Arr.U3.sort((a, b) => a[Key] - b[Key])
        Arr.D3.sort((a, b) => a[Key] - b[Key])
        Arr.B3.sort((a, b) => a[Key] - b[Key])
        Arr.T3.sort((a, b) => a[Key] - b[Key])
        Arr.U2.sort((a, b) => a[Key] - b[Key])
        Arr.D2.sort((a, b) => a[Key] - b[Key])
        Arr.T2.sort((a, b) => a[Key] - b[Key])
        Arr.U1.sort((a, b) => a[Key] - b[Key])
        Arr.D1.sort((a, b) => a[Key] - b[Key])
        return null
      }
      Arr.U3.sort((a, b) => b[Key] - a[Key])
      Arr.D3.sort((a, b) => b[Key] - a[Key])
      Arr.B3.sort((a, b) => b[Key] - a[Key])
      Arr.T3.sort((a, b) => b[Key] - a[Key])
      Arr.U2.sort((a, b) => b[Key] - a[Key])
      Arr.D2.sort((a, b) => b[Key] - a[Key])
      Arr.T2.sort((a, b) => b[Key] - a[Key])
      Arr.U1.sort((a, b) => b[Key] - a[Key])
      Arr.D1.sort((a, b) => b[Key] - a[Key])
      return null
    },
    SumByKey(Arr, Key) {
      if (Arr.length > 0) {
        return Arr.reduce((acc, ele) => acc + ele[Key], 0)
      }
      return 0.0
    },
    CalMax(Arr, Key) {
      if (Arr.length > 0) {
        return Arr.reduce(
          // eslint-disable-next-line
          (acc, ele) => (acc = acc > ele[Key] ? acc : ele[Key]),
          0,
        )
      }
      return 0.0
    },
    // สีใหม่
    // eslint-disable-next-line consistent-return
    ColorReport(amount, limit) {
      const DangerClass = 'table-danger'
      const WarningClass = 'table-warning'
      if (amount >= limit) {
        return DangerClass
      }
      // eslint-disable-next-line no-mixed-operators
      const x = (amount * 100 / limit)
      if (x >= 80) {
        return WarningClass
      }
    },
    rowClass(item, type) {
      const DangerClass = 'table-danger'
      const WarningClass = 'table-warning'
      const SuccessClass = 'table-success'
      if (!item || type !== 'row') {
        return
      }
      if (item.RowColor === 4) {
        // eslint-disable-next-line consistent-return
        return SuccessClass
      }
      if (item.RowColor === 3) {
        // eslint-disable-next-line consistent-return
        return DangerClass
      }
      if (item.RowColor === 2) {
        // eslint-disable-next-line consistent-return
        return WarningClass
      }
    },
    CalRow(item) {
      // console.log(item)
      if (item.status_result === 'win') {
        return 4
      }
      if (item.bet_digit === 3) {
        if (item.bet_type === 'UP') {
          return this.CalRowColor(item.totalAmount, this.RateData.BetLimitU3)
        }
        if (item.bet_type === 'DOWN_F') {
          return this.CalRowColor(item.totalAmount, this.RateData.BetLimitD3)
        }
        if (item.bet_type === 'DOWN_B') {
          return this.CalRowColor(item.totalAmount, this.RateData.BetLimitD3)
        }
        if (item.bet_type === 'TOT') {
          return this.CalRowColor(item.totalAmount, this.RateData.BetLimitT3)
        }
      } else if (item.bet_digit === 2) {
        if (item.bet_type === 'UP') {
          return this.CalRowColor(item.totalAmount, this.RateData.BetLimitU2)
        }
        if (item.bet_type === 'DOWN') {
          return this.CalRowColor(item.totalAmount, this.RateData.BetLimitD2)
        }
        if (item.bet_type === 'TOT') {
          return this.CalRowColor(item.totalAmount, this.RateData.BetLimitT2)
        }
      } else if (item.bet_digit === 1) {
        if (item.bet_type === 'UP') {
          return this.CalRowColor(item.totalAmount, this.RateData.BetLimitU1)
        }
        if (item.bet_type === 'DOWN') {
          return this.CalRowColor(item.totalAmount, this.RateData.BetLimitD1)
        }
      }
      return 0
    },
    CalRowColor(Amount, BetLimit) {
      if (Amount >= BetLimit) {
        return 3
      }
      if (this.Calpercen(Amount, BetLimit) >= 80) {
        return 2
      }
      return 0
    },
    Calpercen(partialValue, totalValue) {
      return ((100 * partialValue) / totalValue).toFixed(2)
      // return (100 * partialValue) / totalValue
    },
    FindSublist(Val) {
      const Index = this.LottoList.findIndex(({ value }) => value === Val)
      if (Index >= 0) {
        this.StatusResult = true
      }
      this.LottoSubList = this.LottoList[Index].SubList
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems
      this.totalRows = filteredItems.length
    },
    ClearItems() {
      this.RoundData = {}
      this.RateData = {}
      this.BetListName = null
      this.RateInput = {
        LottoHead: null,
        LottoSubHead: null,
        RoundID: null,
        U3: 0,
        D3: 0,
        B3: 0,
        T3: 0,
        U2: 0,
        D2: 0,
        T2: 0,
        U1: 0,
        D1: 0,
        BetLimitU3: 0,
        BetLimitD3: 0,
        BetLimitB3: 0,
        BetLimitT3: 0,
        BetLimitU2: 0,
        BetLimitD2: 0,
        BetLimitT2: 0,
        BetLimitU1: 0,
        BetLimitD1: 0,
        PayLimitU3: 0,
        PayLimitD3: 0,
        PayLimitB3: 0,
        PayLimitT3: 0,
        PayLimitU2: 0,
        PayLimitD2: 0,
        PayLimitT2: 0,
        PayLimitU1: 0,
        PayLimitD1: 0,
      }
      this.BetData = {
        U3: [],
        D3: [],
        B3: [],
        T3: [],
        U2: [],
        D2: [],
        T2: [],
        U1: [],
        D1: [],
      }
      this.BetDetail = {
        U3: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        D3: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        B3: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        T3: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        U2: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        D2: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        T2: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        U1: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
        D1: {
          buy: 0,
          com: 0,
          revenue: 0,
          pay: 0,
          balance: 0,
        },
      }
      this.BetList = []
      this.StopRefresh()
      this.TimeRefresh = momenttz().add(this.TimeToRefresh + 1, 'seconds')
    },
    StartRefresh() {
      this.Interval = setInterval(() => {
        const DateNow = momenttz()
        this.DiffRefresh = this.TimeRefresh.diff(DateNow, 'seconds')
        if (this.DiffRefresh <= 0) {
          // eslint-disable-next-line no-underscore-dangle
          this.GetBet(this.RoundData._id)
        }
      }, 1000)
    },
    StopRefresh() {
      clearInterval(this.Interval)
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    CalNameDate(Val) {
      const MonthTH = [
        '',
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม',
      ]
      const Year = Number(momenttz(Val).tz('Asia/Bangkok').format('YYYY')) + 543
      const Month = momenttz(Val).tz('Asia/Bangkok').format('M')
      const Day = momenttz(Val).tz('Asia/Bangkok').format('DD')
      const Time = momenttz(Val).tz('Asia/Bangkok').format('HH:mm')

      return `${Day} ${MonthTH[Month]} ${Year} ${Time}`
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>
<style scoped>
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  /* border: 1px solid #ddd; */
}
thead th {
  background-color: #746d94 !important;
  color: #fff;
  text-align: center;
}
thead th {
  padding: 1rem !important;
}

tbody {
  background-color: #f1e8e8;
}

caption {
  padding: 10px;
  caption-side: bottom;
}

/* table {
    border-collapse: collapse;
    letter-spacing: 1px;
  } */
/* th,
  td {
    text-align: left;
    padding: 8px;
  }*/

tr:nth-child(even) {
  background-color: #f2f2f2;
}

td,
th {
  border: 1px solid rgb(190, 190, 190);
  padding: 5px 10px;
}

td {
  color: #000;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}
</style>
